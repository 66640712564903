<template>
  <div class="menu_data">

<ToolBar/>
    <div>
      <v-container>
        <!-- ข้อมูล -->
        <v-row dense>
          <v-col cols="12"
            ><v-subheader
              class="subtitle-1 text-decoration-underline d-flex justify-center"
              >จัดการ</v-subheader
            ></v-col
          >
          <v-col cols="3" md="3" v-for="(item, i) in menu_manage_item" :key="i">
            <v-layout
              column
              align-center
              link
              @click="$router.push({ path: item.route })"
            >
              <v-flex>
                <v-avatar size="60" color="deep-purple lighten-5">
                  <v-icon
                    x-large
                    color="purple accent-3"
                    v-text="item.icon"
                  ></v-icon>
                </v-avatar>
                <p
                  class="caption font-weight-bold mt-1 text-center"
                  v-text="item.text"
                ></p>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>

        <!-- <v-divider></v-divider> -->
        <v-card height="50" flat> </v-card>
      </v-container>
    </div>

    <BottomNavigation />
  </div>
</template>

<script>

import ToolBar from "@/components/Layouts/ToolBar";
import BottomNavigation from "@/components/Layouts/BottomNavigation";

export default {
  name: "Menu_Data",
  components: {
    BottomNavigation,
    ToolBar,
    // SideBar,
  },

  data: () => ({
    // /path
    menu_manage_item: [
      { text: "เปิดงวด", icon: "mdi-new-box", route: "/new_period" },
      { text: "เลือดงวด", icon: "mdi-selection-ellipse-arrow-inside", route: "" },
      { text: "ชนิดแทง", icon: "mdi-list-status", route: "" },
    ],

    test: true,
  }),
  methods: {},
};
</script>

<style></style>
